import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx } from "vue";
import _imports_0 from '../../../public/castle.png';
import _imports_1 from '../../../public/jyen.png';
import _imports_2 from '../../../public/jycn.png';
import _imports_3 from '../../../public/zden.png';
import _imports_4 from '../../../public/zdcn.png';
import _imports_5 from '../../../public/codeen.png';
import _imports_6 from '../../../public/codecn.png';
const _hoisted_1 = {
  key: 0
};
const _hoisted_2 = {
  key: 1
};
const _hoisted_3 = {
  class: "backgroundcss"
};
const _hoisted_4 = {
  style: {
    "float": "right",
    "padding": "0px 14px",
    "height": "44px",
    "display": "flex",
    "flex-direction": "row",
    "align-items": "center"
  }
};
const _hoisted_5 = {
  style: {
    "padding-top": "62px"
  }
};
const _hoisted_6 = {
  style: {
    "float": "left"
  },
  class: "bannertitle systemfont"
};
const _hoisted_7 = {
  class: "outerr"
};
const _hoisted_8 = {
  key: 0,
  class: "errmsg systemfont"
};
const _hoisted_9 = {
  key: 1,
  class: "errmsg systemfont"
};
const _hoisted_10 = {
  style: {
    "padding": "12px 0 0 0",
    "display": "flex",
    "flex-direction": "row",
    "justify-content": "center",
    "align-items": "center"
  }
};
const _hoisted_11 = {
  class: "nocode systemfont"
};
const _hoisted_12 = {
  style: {
    "padding": "12px 0 0 0",
    "display": "flex",
    "flex-direction": "row",
    "justify-content": "center",
    "align-items": "center"
  }
};
const _hoisted_13 = {
  style: {
    "padding": "30px 14px"
  }
};
const _hoisted_14 = {
  class: "errtitle systemfont"
};
const _hoisted_15 = {
  class: "errcont systemfont"
};
const _hoisted_16 = {
  class: "errtitle systemfont"
};
const _hoisted_17 = {
  class: "errcont systemfont"
};
const _hoisted_18 = {
  class: "poptitle systemfont",
  style: {
    "border": "0.5px solid #EDEDF0"
  }
};
const _hoisted_19 = {
  class: "popcontent systemfont",
  style: {
    "margin": "16px 0 32px 0"
  }
};
const _hoisted_20 = {
  key: 0
};
const _hoisted_21 = {
  key: 0,
  src: _imports_1,
  style: {
    "width": "100%"
  }
};
const _hoisted_22 = {
  key: 1,
  src: _imports_2,
  style: {
    "width": "100%"
  }
};
const _hoisted_23 = {
  key: 1
};
const _hoisted_24 = {
  key: 0,
  src: _imports_3,
  style: {
    "width": "100%"
  }
};
const _hoisted_25 = {
  key: 1,
  src: _imports_4,
  style: {
    "width": "100%"
  }
};
const _hoisted_26 = {
  class: "poptitle systemfont",
  style: {
    "border": "0.5px solid #EDEDF0"
  }
};
const _hoisted_27 = {
  class: "popcontent systemfont",
  style: {
    "margin": "16px 0 32px 0"
  }
};
const _hoisted_28 = {
  key: 0,
  src: _imports_5,
  style: {
    "width": "100%"
  }
};
const _hoisted_29 = {
  key: 1,
  src: _imports_6,
  style: {
    "width": "100%"
  }
};
const _hoisted_30 = {
  class: "poptitleab systemfont"
};
const _hoisted_31 = {
  style: {
    "position": "absolute",
    "right": "0",
    "bottom": "0",
    "left": "0",
    "top": "54px",
    "overflow": "auto"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Loading = _resolveComponent("Loading");
  const _component_InputField = _resolveComponent("InputField");
  const _component_van_popup = _resolveComponent("van-popup");
  const _component_FapiaoFAQ = _resolveComponent("FapiaoFAQ");
  return _openBlock(), _createElementBlock("div", null, [$data.showLoading ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_Loading)])) : _createCommentVNode("", true), $data.showMain ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", {
    class: _normalizeClass($data.languagecn),
    onClick: _cache[0] || (_cache[0] = $event => $options.onChangeLanguage('zh'))
  }, "中", 2), _cache[16] || (_cache[16] = _createElementVNode("div", {
    class: "language_middle"
  }, null, -1)), _createElementVNode("div", {
    class: _normalizeClass($data.languageen),
    onClick: _cache[1] || (_cache[1] = $event => $options.onChangeLanguage('en'))
  }, "EN", 2)]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('ManPage.PageContent')), 1), _cache[17] || (_cache[17] = _createElementVNode("div", {
    style: {
      "float": "right",
      "position": "absolute",
      "top": "62px",
      "right": "14px"
    }
  }, [_createElementVNode("img", {
    src: _imports_0,
    style: {
      "max-width": "90px",
      "max-height": "60px"
    }
  })], -1))])]), _createVNode(_component_InputField, {
    upfieldid: "upfield",
    "model-value": $data.kptqcode,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $data.kptqcode = $event),
    placeholder: $data.placehd,
    placeholderi: $data.placehdi,
    outstyle: $data.kptqcodestyle,
    "onUpdate:outstyle": _cache[3] || (_cache[3] = $event => $data.kptqcodestyle = $event)
  }, null, 8, ["model-value", "placeholder", "placeholderi", "outstyle"]), _withDirectives(_createElementVNode("div", _hoisted_7, [this.kptqcode ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString($data.barerrormsg), 1)) : _createCommentVNode("", true), !this.kptqcode ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString($data.placehd), 1)) : _createCommentVNode("", true)], 512), [[_vShow, $data.errmsg]]), _createElementVNode("div", _hoisted_10, [_createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t('ManPage.Remarkft')), 1), _withDirectives(_createElementVNode("span", null, " ", 512), [[_vShow, $data.addspace]]), _createElementVNode("span", {
    class: "cliclhere systemfont",
    onClick: _cache[4] || (_cache[4] = $event => this.showLocation = true)
  }, _toDisplayString(_ctx.$t('ManPage.Remarkaf')), 1)]), _createElementVNode("div", _hoisted_12, [_createElementVNode("span", {
    class: "cliclhere systemfont",
    onClick: _cache[5] || (_cache[5] = $event => this.showBottom = true)
  }, _toDisplayString(_ctx.$t('AppPage.FapiaoFAQ')), 1)]), _createElementVNode("div", _hoisted_13, [_createElementVNode("button", {
    class: _normalizeClass($data.searchbtn),
    onClick: _cache[6] || (_cache[6] = (...args) => $options.secrchKpsq && $options.secrchKpsq(...args))
  }, _toDisplayString(_ctx.$t('ManPage.Button')), 3)]), _createVNode(_component_van_popup, {
    show: $data.showFail,
    "onUpdate:show": _cache[9] || (_cache[9] = $event => $data.showFail = $event),
    round: "",
    style: {
      margin: '28px',
      padding: '20px',
      width: 'auto'
    }
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$t('ManPage.errtitle')), 1), _createElementVNode("div", _hoisted_15, _toDisplayString($data.FailErrMsg), 1), _createElementVNode("button", {
      class: _normalizeClass($data.closeyes),
      onClick: _cache[7] || (_cache[7] = $event => this.showFail = false)
    }, _toDisplayString(_ctx.$t('ManPage.closeyes')), 3), _createElementVNode("button", {
      class: _normalizeClass($data.closecancel),
      onClick: _cache[8] || (_cache[8] = $event => this.showFail = false)
    }, _toDisplayString(_ctx.$t('ManPage.closecancel')), 3)]),
    _: 1
  }, 8, ["show"]), _createVNode(_component_van_popup, {
    show: $data.showLast,
    "onUpdate:show": _cache[12] || (_cache[12] = $event => $data.showLast = $event),
    round: "",
    style: {
      margin: '28px',
      padding: '20px',
      width: 'auto'
    }
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.$t('ManPage.errtitle')), 1), _createElementVNode("div", _hoisted_17, _toDisplayString($data.notlasterr), 1), _createElementVNode("button", {
      class: _normalizeClass($data.closeyes),
      onClick: _cache[10] || (_cache[10] = $event => this.showLast = false)
    }, _toDisplayString(_ctx.$t('ManPage.closeyes')), 3), _createElementVNode("button", {
      class: _normalizeClass($data.closecancel),
      onClick: _cache[11] || (_cache[11] = $event => {
        this.showLast = false;
        this.showOrderCode = true;
      })
    }, _toDisplayString($data.findordercode), 3)]),
    _: 1
  }, 8, ["show"]), _createVNode(_component_van_popup, {
    show: $data.showOrderCode,
    "onUpdate:show": _cache[13] || (_cache[13] = $event => $data.showOrderCode = $event),
    round: "",
    closeable: "",
    position: "bottom",
    style: {
      height: 'auto'
    }
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_18, _toDisplayString($data.ordercode), 1), _createElementVNode("div", _hoisted_19, [this.returncode == 'jy' ? (_openBlock(), _createElementBlock("div", _hoisted_20, [this.addspace ? (_openBlock(), _createElementBlock("img", _hoisted_21)) : (_openBlock(), _createElementBlock("img", _hoisted_22))])) : (_openBlock(), _createElementBlock("div", _hoisted_23, [this.addspace ? (_openBlock(), _createElementBlock("img", _hoisted_24)) : (_openBlock(), _createElementBlock("img", _hoisted_25))]))])]),
    _: 1
  }, 8, ["show"]), _createVNode(_component_van_popup, {
    show: $data.showLocation,
    "onUpdate:show": _cache[14] || (_cache[14] = $event => $data.showLocation = $event),
    round: "",
    closeable: "",
    position: "bottom",
    style: {
      height: 'auto'
    }
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_26, _toDisplayString(_ctx.$t('ManPage.location')), 1), _createElementVNode("div", _hoisted_27, [this.addspace ? (_openBlock(), _createElementBlock("img", _hoisted_28)) : (_openBlock(), _createElementBlock("img", _hoisted_29))])]),
    _: 1
  }, 8, ["show"]), _createVNode(_component_van_popup, {
    show: $data.showBottom,
    "onUpdate:show": _cache[15] || (_cache[15] = $event => $data.showBottom = $event),
    round: "",
    closeable: "",
    position: "bottom",
    style: {
      height: '90%'
    }
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_30, _toDisplayString(_ctx.$t('ManPage.poptitle')), 1), _createElementVNode("div", _hoisted_31, [_createVNode(_component_FapiaoFAQ)])]),
    _: 1
  }, 8, ["show"])])) : _createCommentVNode("", true)]);
}