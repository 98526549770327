import "core-js/modules/es.array.push.js";
import InputField from './InputField.vue';
import Loading from './loading.vue';
import FapiaoFAQ from './FapiaoFAQ.vue';
export default {
  components: {
    InputField,
    Loading,
    FapiaoFAQ
  },
  inject: ['reload'],
  data() {
    return {
      kptqcode: '',
      kptqcodestyle: 'frame van_kpborder systemfont',
      hrwidth: document.documentElement.clientWidth - 63 + 'px',
      changeIndex: 0,
      isBtmPh: false,
      upfieldid: 'upfield',
      showBottom: false,
      showLocation: false,
      showOrderCode: false,
      errmsg: false,
      addspace: false,
      tempwidth: document.documentElement.clientWidth + 'px',
      tempheight: document.documentElement.clientHeight + 'px',
      language: '',
      languagecn: 'language_cn systemfont',
      languageen: 'language_en systemfont',
      kpje: 0,
      showFail: false,
      showLast: false,
      notlasterr: '',
      searchbtn: 'searchbtn searchbtnBgdnon systemfont',
      closeyes: 'closebtny btnyes systemfont',
      closecancel: 'closebtny btncancel systemfont',
      timer: null,
      placehd: this.$t('ManPage.Inputbar'),
      placehdi: this.$t('ManPage.Inputbari'),
      barerrormsg: this.$t('ManPage.barerrormsg'),
      showMain: true,
      showLoading: false,
      FailErrMsg: '',
      Remarkft: '',
      findordercode: '',
      ordercode: '',
      returncode: ''
    };
  },
  computed: {
    kpcodeStatus() {
      return this.$store.state.kpcodeStatus;
    }
  },
  mounted() {
    this.$i18n.locale = this.$store.state.lang;
    if (this.$store.state.lang == 'en') {
      this.languagecn = 'language_cn langunchosen systemfont';
      this.languageen = 'language_en langchosen systemfont';
      this.addspace = true;
    } else {
      this.languagecn = 'language_cn langchosen systemfont';
      this.languageen = 'language_en langunchosen systemfont';
      this.addspace = false;
    }
    this.$store.commit('setKpcodeStatus', true);
    this.$store.commit('setOrders', []);
    this.$store.commit('setOrderBillNos', []);
    this.$store.commit('setOrderList', []);
    this.$store.commit('setInvoiceRequest', {});
    this.$store.commit('setInvoiceType', []);
    this.$store.commit('setFapiaoAmount', '');
    this.$store.commit('setInvoiceRequestId', '');
    this.$store.commit('setInvoiceRequestDatetime', '');
    this.$store.commit('setInvoiceRequestAmount', '');
    this.$store.commit('setInvoiceRequestInvoiceType', '');
    this.$store.commit('setPdfhref', '');
    this.$store.commit('setKptqcode', '');
    if (this.$route.query.m && this.$route.query.v) {
      this.showMain = false;
      this.showLoading = true;
      this.onSetKpsq(this.$route.query.v);
    } else {
      this.showMain = true;
      this.showLoading = false;
    }
  },
  watch: {
    kpcodeStatus: {
      handler: function (newValue) {
        if (newValue == true) {
          this.errmsg = false;
        }
      }
    },
    kptqcode: {
      handler: function (newValue) {
        // if (newValue.length > 12) {
        //     this.kptqcode = newValue.substring(0, 12)
        //     newValue = newValue.substring(0, 12)
        // }
        this.$store.commit('setKptqcode', newValue);
        this.errmsg = false;
        this.kptqcodestyle = 'frame van_kpbordering systemfont';
        var numReg = /^[0-9A-Za-z\@\#\=]+$/;
        var numRe = new RegExp(numReg);
        if (numRe.test(newValue) && newValue.length >= 8 && newValue.length <= 12) {
          this.searchbtn = 'searchbtn searchbtnBgd systemfont';
          this.$store.commit('setKpcodeStatus', true);
        } else {
          this.searchbtn = 'searchbtn searchbtnBgdnon systemfont';
          this.$store.commit('setKpcodeStatus', false);
        }
      }
    }
  },
  methods: {
    secrchKpsq() {
      var numReg = /^[0-9A-Za-z\@\#\=]+$/;
      var numRe = new RegExp(numReg);
      if (numRe.test(this.kptqcode) && this.kptqcode.length <= 12 && this.kptqcode.length >= 8) {
        this.$store.commit('setKpcodeStatus', true);
        this.showMain = false;
        this.showLoading = true;
        this.axios.post('cdapply/GetOrderInform/', {
          "ordersn": this.kptqcode
        }).then(response => {
          clearTimeout(this.timer);
          this.timer = setTimeout(() => {
            var resdata = response.data;
            this.onSubmitKpsq(resdata);
          }, 500);
        }).catch(err => {
          this.$router.push('systemerr');
        });
      } else {
        this.$store.commit('setKpcodeStatus', false);
        this.kptqcodestyle = 'frame van_kpbordererr systemfont';
        this.searchbtn = 'searchbtn searchbtnBgdnon systemfont';
        this.errmsg = true;
      }
    },
    onSetKpsq(vorder) {
      this.axios.post('cdapply/GetOriOrderBill/', {
        "ordersn": vorder
      }).then(response => {
        var resdata = response.data;
        this.onSubmitKpsq(resdata);
      }).catch(err => {
        this.$router.push('systemerr');
      });
    },
    onSubmitKpsq(resdata) {
      if (resdata.code == 0) {
        if (resdata.data['code'] == 3044) {
          if (this.$store.state.lang == 'en') {
            this.notlasterr = this.$t('ErrMsg.latestxy') + resdata.data['TransNo'] + this.$t('ErrMsg.latestxe') + resdata.data['OrderTransactionTime'] + this.$t('ErrMsg.latestxs');
          } else {
            this.notlasterr = this.$t('ErrMsg.latestxy') + resdata.data['OrderTransactionTime'] + this.$t('ErrMsg.latestxe') + resdata.data['TransNo'] + this.$t('ErrMsg.latestxs');
          }
          this.Remarkft = this.$t('ManPage.Remarkfjy');
          this.showMain = true;
          this.showLoading = false;
          this.findordercode = this.$t('ManPage.findjycode');
          this.ordercode = this.$t('ManPage.jycode');
          this.showLast = true;
          this.returncode = 'jy';
        } else if (resdata.data['code'] == 3045) {
          if (this.$store.state.lang == 'en') {
            this.notlasterr = this.$t('ErrMsg.latestsy') + resdata.data['TransNo'] + this.$t('ErrMsg.latestse') + resdata.data['OrderTransactionTime'] + this.$t('ErrMsg.latestss');
          } else {
            this.notlasterr = this.$t('ErrMsg.latestsy') + resdata.data['OrderTransactionTime'] + this.$t('ErrMsg.latestse') + resdata.data['TransNo'] + this.$t('ErrMsg.latestss');
          }
          this.Remarkft = this.$t('ManPage.Remarkfzd');
          this.showMain = true;
          this.showLoading = false;
          this.findordercode = this.$t('ManPage.findzdcode');
          this.ordercode = this.$t('ManPage.zdcode');
          this.showLast = true;
          this.returncode = 'zd';
        } else if (resdata.data['InvoiceStatus'] == 'ERR') {
          this.$store.commit('setInvoiceRequestAmount', resdata.data['InvoiceAmount']);
          this.$store.commit('setInvoiceRequestDatetime', resdata.data['InvoiceDatetime']);
          this.$store.commit('setInvoiceRequestInvoiceType', resdata.data['InvoiceType']);
          this.$router.push('kpfailed');
        } else if (resdata.data['InvoiceStatus'] == 'MAKE') {
          this.$store.commit('setInvoiceRequestAmount', resdata.data['InvoiceAmount']);
          this.$store.commit('setInvoiceRequestDatetime', resdata.data['InvoiceDatetime']);
          this.$store.commit('setInvoiceRequestInvoiceType', resdata.data['InvoiceType']);
          this.$router.push('dealing');
        } else if (resdata.data['InvoiceStatus'] == 'INV') {
          this.$store.commit('setInvoiceRequest', resdata.data['InvoiceRequest']);
          this.$store.commit('setNextPage', 'kpsuccess');
          this.$router.push('searchinvoice');
        } else if (resdata.data['fapiaoAmount'] == 0) {
          this.showMain = true;
          this.showLoading = false;
          this.FailErrMsg = this.$t('ErrMsg.amount'), this.showFail = true;
        } else {
          this.$store.commit('setOrders', resdata.data['orders']);
          this.$store.commit('setFapiaoAmount', resdata.data['fapiaoAmount']);
          this.$store.commit('setInvoiceType', resdata.data['invoiceType']);
          this.$store.commit('setIsSHDR', resdata.data['isSHDR']);
          this.$store.commit('setOrderBillNos', resdata.data['OrderBillNos']);
          this.$store.commit('setOrderList', resdata.data['OrderList']);
          this.$store.commit('setCostomerList', resdata.data['costomerList']);
          this.$store.commit('setKpinform', {
            "buyerType": 0
          });
          this.$store.commit('setReceive', {
            "sendType": 0
          });
          this.$router.push('fpkj');
        }
      } else {
        this.showMain = true;
        this.showLoading = false;
        if (resdata.code == '3001' && resdata.message == 'PSPC') {
          this.FailErrMsg = this.$t('ErrMsg.paper');
          this.showFail = true;
        } else if (resdata.code == '3001' && resdata.message == 'ESEC') {
          this.FailErrMsg = this.$t('ErrMsg.check');
          this.showFail = true;
        } else if (resdata.code == '3003') {
          this.FailErrMsg = this.$t('ErrMsg.current');
          this.showFail = true;
        } else if (resdata.code == '3044') {
          this.FailErrMsg = this.$t('ErrMsg.latestx');
          this.showFail = true;
        } else if (resdata.code == '3009') {
          this.FailErrMsg = this.$t('ErrMsg.amount');
          this.showFail = true;
        } else if (resdata.code == '3099') {
          this.FailErrMsg = this.$t('ErrMsg.cancell');
          this.showFail = true;
        } else if (resdata.code == '3042' || resdata.code == '3093') {
          this.FailErrMsg = this.$t('ErrMsg.incorrect');
          this.showFail = true;
        } else if (resdata.code == '0025') {
          this.FailErrMsg = this.$t('ErrMsg.available');
          this.showFail = true;
        } else {
          this.FailErrMsg = this.$t('ErrMsg.current');
          this.showFail = true;
        }
      }
    },
    onChangeLanguage(lang) {
      this.$i18n.locale = lang;
      this.$store.commit('setLang', lang);
      localStorage.setItem('lang', lang);
      // this.reload();
      this.placehd = this.$t('ManPage.Inputbar');
      this.placehdi = this.$t('ManPage.Inputbari');
      this.barerrormsg = this.$t('ManPage.barerrormsg');
      if (lang == 'en') {
        this.languagecn = 'language_cn langunchosen systemfont';
        this.languageen = 'language_en langchosen systemfont';
        this.addspace = true;
        document.title = 'Shanghai Disney Resort';
      } else if (lang == 'zh') {
        this.languagecn = 'language_cn langchosen systemfont';
        this.languageen = 'language_en langunchosen systemfont';
        this.addspace = false;
        document.title = '上海迪士尼度假区';
      }
    }
  }
};